import axios from 'axios'

export default {
    namespaced: true,
    state: {
        accountData: {},
        accountDetails:{},
        locationData:{},
        pdfList:{},
        recentPurchase:{},
        diccountData:[],
        careerData:[]
    },
    getters: {
        getCareerData(state){
            return state.careerData;
        },
        getDiscountData(state){
            return state.diccountData;
        },
        getRecentPurchase(state){
            return state.recentPurchase;
        },
        getPdfList(state){
           return state.pdfList;
        },
        getLocation(state){
            return state.locationData
        },
        getAccounts(state){
            return state.accountData
        },
        getAccountDetails(state){
            return state.accountDetails
        },
    },
    actions: {
        careers({commit},{data, method}) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: method,
                    url: process.env.VUE_APP_API_SERVER + '/api/career'+(method === 'delete' || method === 'put' ? '/'+data.id:''),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }
                if(method === 'get'){
                    config.params = data
                }else{
                    config.data = data
                }

                axios(config)
                    .then(response => {
                        if(method === 'get' && data.id === undefined) {
                            commit('setCareerData', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        pdfList({commit}) {
            return new Promise((resolve, reject) => {
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/pdfUserList',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: {active: 1}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                        commit('setPdfList', response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        sendContactUs(_,data){
            return new Promise((resolve, reject) => {
                //let data = {}
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/contact-us',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    }).catch((error) => {
                    if (!error.status) {
                        console.log('Error if', error)
                    } else {
                        reject(error)
                    }
                })
            })
        },
        contactUs(_,formdata){
            return new Promise((resolve, reject) => {

                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/contact-us',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: formdata
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        getAccountData({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/listAccounts',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setAccountData',response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        discountNames({commit}){
            return new Promise((resolve, reject) => {
                let data = {}
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/discountlbl',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + (localStorage.getItem('vtoken') !== '' && localStorage.getItem('vtoken') !== null ? localStorage.getItem('vtoken') : this.getters['userAccess/accessToken'])
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setDiscountData',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        accountDetails({commit},options){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/accountDetails',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + (localStorage.getItem('vtoken') !== '' && localStorage.getItem('vtoken') !== null ? localStorage.getItem('vtoken') : this.getters['userAccess/accessToken'])
                    },
                    params: data
                }

                axios(config)
                    .then(response => {

                        commit('setAccountDetails',response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateMethod(_,options){
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/accountBillingMethods/'+options.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + (localStorage.getItem('vtoken') !== '' && localStorage.getItem('vtoken') !== null ? localStorage.getItem('vtoken') : this.getters['userAccess/accessToken'])
                    },
                    data: {...options}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        deleteMethod({getters,commit},options){
            return new Promise((resolve, reject) => {
                let data = {}
                var config = {
                    method: 'delete',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/accountBillingMethods/'+options.billingid,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + (localStorage.getItem('vtoken') !== '' && localStorage.getItem('vtoken') !== null ? localStorage.getItem('vtoken') : this.getters['userAccess/accessToken'])
                    },
                    params: data
                }

                axios(config)
                    .then(response => {

                        console.log('del', response)
                        let adetailsbill = getters.getAccountDetails;

                        adetailsbill.billing = adetailsbill.billing.filter(function( obj ) {
                            return obj.id !== options.billingid;
                        });

                        commit('setAccountDetails',adetailsbill)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        addMethod({getters,commit},formdata){
            return new Promise((resolve, reject) => {
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/accountBillingMethods',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + (localStorage.getItem('vtoken') !== '' && localStorage.getItem('vtoken') !== null ? localStorage.getItem('vtoken') : this.getters['userAccess/accessToken'])
                    },
                    data: formdata
                }

                axios(config)
                    .then(response => {
                        console.log('add card', response)
                        if(response.data.success) {
                            let adetails = getters.getAccountDetails;
                            console.log('add card push', response.data.billing)
                            adetails.billing.push(response.data.billing)
                            console.log('add card push com', adetails)
                            commit('setAccountDetails', adetails)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateAccount(_,options){
            return new Promise((resolve, reject) => {
                var config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/userAccount',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: {...options}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateMethodDefault(_,options){
            return new Promise((resolve, reject) => {
                var config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/accountBillingMethods/'+options.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + (localStorage.getItem('vtoken') !== '' && localStorage.getItem('vtoken') !== null ? localStorage.getItem('vtoken') : this.getters['userAccess/accessToken'])
                    },
                    data: {...options}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        getTokenXCreds(){
            return new Promise((resolve, reject) => {
                let data = {origin:"FE"}
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/admin/accountBToken',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + (localStorage.getItem('vtoken') !== '' && localStorage.getItem('vtoken') !== null ? localStorage.getItem('vtoken') : this.getters['userAccess/accessToken'])
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        setLocations({commit}){
            return new Promise((resolve, reject) => {
                let data = { }
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/locations',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setLocationData',response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        recentPurchase(_,data){
            return new Promise((resolve, reject) => {
                if(!this.getters['userAccess/accessToken']){
                    return false
                }
                //let data = { }
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/api/recentPurchases',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        //commit('setRecentPurchaseData',response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        actionSetRecentPurchase({commit},rez){
            return new Promise((resolve) => {
                commit('setRecentPurchaseData',rez)
                resolve();
            })
        }

    },
    mutations: {
        setCareerData(state, rp){
            state.careerData = !rp ? {} : rp
        },
        setDiscountData(state, rp){
            state.diccountData = !rp ? {} : rp
        },
        setRecentPurchaseData(state, rp){
            state.recentPurchase = !rp ? {} : rp
        },
        setPdfList(state, list){
            state.pdfList = !list ? {} : list
        },
        setLocationData(state, locationData){
            state.locationData = !locationData ? {} : locationData
        },
        setAccountData(state, accountData){
            state.accountData = !accountData ? {} : accountData
        },
        setAccountDetails(state, accountData){
            state.accountDetails = !accountData ? {} : accountData
        },
    }
}