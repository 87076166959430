<template>
  <div>
    <span v-if="!filter">
      <h4>Orders</h4>
    </span>
    <div class="card" v-if="!filter">
      <div class="card-header">
        <h3 class="card-title">Filters</h3>
        <!-- /.card-tools -->
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label>Invoice Date From</label>
              <input type="date" class="form-control" v-model="filterForm.invoiceDateFrom">
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label>Invoice Date To</label>
              <input type="date" class="form-control" v-model="filterForm.invoiceDateTo">
            </div>
          </div>
          <div class="col-sm-3 border-left">
            <div class="form-group">
              <label>Order Date From</label>
              <input type="date" class="form-control" v-model="filterForm.orderDateFrom">
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label>Order Date To</label>
              <input type="date" class="form-control" v-model="filterForm.orderDateTo">
            </div>
          </div>
        </div>

        <div class="row">
          <!--
          <div class="col-sm-2">
            <div class="form-group">
              <label for="exampleSelectRounded0">Location</label>
              <select class="custom-select rounded-0" v-model="this.filterForm.Location">
                <option></option>
                <option v-for="loc in locationData" :key="loc.Loc" :value="loc.Loc">{{ loc.Name }}</option>
              </select>
            </div>
          </div>
          -->
          <div class="col-sm-2">
            <div class="form-group">
              <label for="exampleSelectRounded0">Status</label>
              <select class="custom-select form-select" v-model="this.filterForm.paystatus">
                <option></option>
                <option value="4">Credits</option>
                <option value="1">Paid</option>
                <option value="0">Not Paid</option>
                <option value="9">Pending</option>
              </select>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="exampleSelectRounded0">Invoice #</label>
              <input type="text" class="form-control" v-model="this.filterForm.Invoicenum" @keyup.enter="runSearch">
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="exampleSelectRounded0">Order #</label>
              <input type="text" class="form-control" v-model="this.filterForm.Ordernum" @keyup.enter="runSearch">
            </div>
          </div>
          <div class="col-sm-2">
            &nbsp;
          </div>
        </div>


      </div>
      <!-- /.card-body -->
      <div class="card-footer">
        <button class="btn btn-primary float-left" @click.prevent="runSearch">Search</button>

        <button class="btn btn-secondary float-right ms-1 " style="border-radius: 50px;" v-show="hasBatchPay"
                @click.prevent="sumMassPayment">Make Payment
        </button>
      </div>
      <!-- /.card-footer -->
    </div>
    <!-- /.card -->

    <table id="orders1" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>&nbsp;</th>
        <th>Order Number</th>
        <th>Inv #'s</th>
        <th>Location</th>
        <th>Total</th>
        <th>Inv Total</th>
        <th>Payment</th>
        <th>Status</th>
        <th>Order Date</th>
        <th>Invoice Date</th>
      </tr>
      </thead>
      <tbody>

      </tbody>
    </table>

    <div
        class="modal fade"
        id="sumBatchModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="sumBatchModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="sumBatchModalLabel">Batch Summary</h5>
            <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="padding">
              <div class="row">
                <table class="table">
                  <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data,acct) in sumData" :key="acct">
                    <td>Your Card ending in {{data['lastfour']}} will be billed for</td>
                    <td>{{"$" + formatCurrency(data['sum'])}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary" id="btnSubmit"
                    @click.prevent="makeMassPayment">
              Make Payment
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import {useToast} from "vue-toastification";
import $ from 'jquery'
import {Modal} from "bootstrap";


export default {
  name: "UserOrders",
  props: {acctnum: Number, filter: Boolean, searchx: String},
  setup() {
    const toast = useToast();
    return {toast};
  },
  data() {
    return {
      loader:"",
      sumBatchModal:"",
      sumData:[],
      search: '',
      batchPayInv:[],
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">'},
      filterForm: {
        invoiceDateTo: null,
        invoiceDateFrom: null,
        orderDateTo: null,
        orderDateFrom: null,
        Location: '',
        paystatus: null,
        Acctnum: null,
        Invoicenum: null,
        Ordernum: null,
        canCharge: null
      }
    }
  },
  watch: {
    "$route"(to) {
      if(to.name === 'userListOrders') {
        this.filterForm.paystatus = null
        if(to.params.searchx === 'payments'){
          this.filterForm.paystatus = 1
        }else if(to.params.searchx === 'pending'){
          //do something later
          this.filterForm.paystatus = 9
        }
        this.runSearch();
      }
    },
  },
  computed: {
    ...mapGetters({
      'orderData': 'orders/getOrders',
      'locationData': 'user/getLocation',
    }),
    hasBatchPay(){
      return this.batchPayInv.length>0
    }
  },
  methods: {
    sumMassPayment(){
      if(this.batchPayInv.length > 0){

        this.loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          color: this.color,
          backgroundColor: this.bgColor,
          height: this.height,
          width: this.width,
          loader: 'dots',
          opacity: .5,
        });

        let batchPayInvWPP = []
        this.batchPayInv.forEach(function (Inv) {
          batchPayInvWPP.push({'invoiceid': Inv})
        });
        console.log('all batch',batchPayInvWPP)

        this.$store.dispatch('orders/sumBatchPayment', batchPayInvWPP)
            .then(data => {
              console.log('sum', data)
              this.sumData = data.sum
              this.sumBatchModal.show();
              this.loader.hide();

              /*
              if(data.success && data.batchId !== null){
                this.toast.success('Payments will be processed shortly please refresh the page for updates');
              }else{
                this.toast.warning('Batch Failed');
              }
              */
            });

      }else{
        alert('No rows selected');
      }
    },
    makeMassPayment() {
      if(this.batchPayInv.length > 0){

        let batchPayInvWPP = []
        this.batchPayInv.forEach(function (Inv) {
            batchPayInvWPP.push({'invoiceid': Inv})
        });

        this.$store.dispatch('orders/makeBatchPayment', batchPayInvWPP)
            .then(data => {
              if(data.success && data.batchId !== null){
                this.toast.success('Payments will be processed shortly please refresh the page for updates');
              }else{
                this.toast.warning('Batch Failed');
              }
              this.sumBatchModal.hide();
            });

      }else{
        alert('No rows selected');
      }
    },
    goTo(rl) {
      this.$router.push(rl)
    },
    runSearch() {
      this.search = $('[aria-controls="orders1"]').val();

      $("#orders1").DataTable().search(this.search).draw();
    },
    onint() {
      $("#orders1_wrapper input[type='search']").off();
      // Use return key to trigger search
      $("#orders1_wrapper input[type='search']").on("keyup", function (evt) {
        this.search = $(this).val();
        if (evt.keyCode == 13) {
          $("#orders1").DataTable().draw();
        }
      });

      let self = this;
      $(".chkINV").on("change", function () {
        let checkboxes = document.getElementsByClassName("chkINV");
        self.batchPayInv = []

        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            self.batchPayInv.push(checkboxes[i].value);
          }
        }
      });
    },
    loadData() {
      let self = this
      //let batchData = []
      $("#orders1").DataTable({
        ajax: function (data, callback) {
          let sPage = (parseInt($("#orders1").DataTable().page()) + 1);
          let search = $("#orders1_wrapper input[type='search']").val();
          let orderCol = data.columns[data.order[0].column].name;
          let orderDir = data.order[0].dir;

          let filterLogic = self.filterForm
          filterLogic.withInvItems = false
          if(filterLogic.Invoicenum !== null && filterLogic.Invoicenum !== undefined && filterLogic.Invoicenum.trim() !== ''){
            filterLogic = {Invoicenum: self.filterForm.Invoicenum}
          }else if(filterLogic.Ordernum !== null && filterLogic.Ordernum !== undefined && filterLogic.Ordernum.trim() !== ''){
            filterLogic = {Ordernum: self.filterForm.Ordernum}
          }

          self.$store.dispatch('orders/getAdminOrderData', {
            options: {
              page: sPage,
              perPage: data.length,
              search,
              orderCol,
              orderDir, ...filterLogic
            }
          }).then(adata => {
            const tmpJson = {
              recordsTotal: adata.data.total, // expected by DataTables to create pagination
              recordsFiltered: adata.data.total, // expected by DataTables to create pagination
              data: adata.data.data, // expected by DataTables to populate the table
              currentPage: adata.current_page // added by me to easily manage correct page displaying
            }

            setTimeout(function () {
              self.onint();
            }, 1000);

            callback(
                tmpJson
            )
          });
        },
        searching: false,
        processing: true,
        serverSide: true,
        order: [[8, 'desc']],
        'columnDefs': [{
          'targets': [0, 1], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }
        ],
        "columns": [
          {
            "data": null,
            "name": null,
            "render": function (data, type, row) {
              let inv = '';

              if (row.InvoiceNumbers !== null && data.paystatus !== 1 &&
                  data.paystatus !== 4 &&
                  row.invamt !== null && row.invamt !== '' && row.invamt > 0) {
                inv = '<input type="checkbox" class="chkINV" value="' + row.InvoiceNumbers + '">';
              }

              return inv;
            }

          },
          {
            "data": null,
            "name": 'Ordnum',
            "render": function (data, type, row) {
              return '<a href="/Admin/OrderDetails/' + row.Ordnum + '">' + row.Ordnum + '</a>';
            }

          },
          {
            "data": null,
            "render": function (data, type, row) {
              let invS = ''
              if (row.InvoiceNumbers !== null) {
                let invx = row.InvoiceNumbers.split(',')
                for (let i = 0; i < invx.length; i++) {
                  invS += '<a href="/Admin/InvoiceDetails/' + invx[i] + '">' + invx[i] + '</a>';
                }
              }

              return invS;
            }
          },
          {
            "data": null,
            "render": function (data, type, row) {
              return row.location === null || row.location.Name === null ? '' : row.location.Name;
            }
          },
          /*
         {
             "data": null,
             "name": 'Acctnum',
             "render": function (data, type, row) {
                 return '<a href="/Admin/AccountDetails/' + row.Acctnum + '">' + row.Acctnum + '</a>';
             }

         },

         {
             "data": null,
             "name": "Company",
             "render": function (data, type, row) {
                 return row.account.Company;
             }
         },
         */
          {
            "data": null,
            "name": "InvoiceInvTotal",
            "render": function (data, type, row) {
              let ttl = 0;
              if (row.InvoiceInvTotal !== null) {
                ttl = row.InvoiceInvTotal !== null ? '$' + parseFloat(row.InvoiceInvTotal).toFixed(2) : '';
              } else {
                ttl = row.OrderInvTotal !== null ? '$' + parseFloat(row.OrderInvTotal).toFixed(2) : '';
              }
              return ttl
            }
          },
          {
            "data": null,
            "name": 'invamt',
            "render": function (data, type, row) {
              let ia = row.invamt;
              if (ia !== '') {
                ia = '$' + Math.abs(ia).toFixed(2);
              }
              if (row.paystatus === 4 || row.shipvia === 'CREDIT') {
                ia = '<span style="color:red;">(' + ia + ')</span>';
              }

              return ia;
            }
          },
          {
            "data": null,
            "name": "payamt",
            "render": function (data, type, row) {
              return row.payamt !== null ? '$' + parseFloat(row.payamt).toFixed(2) : '';
            }
          },
          {
            "data": null,
            "name": 'null',
            "render": function (data) {
              let s = ''

              if (data.paystatus === 1) {
                s = 'Paid';
              } else if (data.paystatus === 0) {
                s = 'Not Paid';
              } else if (data.paystatus === 3) {
                s = '';
              } else if (data.paystatus === 4) {
                //inv text is red
                s = 'Paid';
              }

              if(data.paystatus !== 1 && data.batchStatus !== undefined){
                s = data.batchStatus
              }
              return s;
            }
          },
          {
            "data": null,
            "name": 'OrdDate',
            "render": function (data, type, row) {
              return '<span style="white-space: nowrap;">' + (row.OrdDate !== null ? row.OrdDate.slice(0, 10) : '') + '</span>';
            }
          },
          {
            "data": null,
            "name": 'InvDate',
            "render": function (data, type, row) {
              return row.InvDate !== null ? row.InvDate.slice(0, 10) : '';
            }
          },
        ],
        // eslint-disable-next-line no-unused-vars
        "rowCallback": function (row, data, index) {
          let color = '#000000';
          if (data.paystatus === 1) {
            color = 'green';
          } else if (data.paystatus === 3) {
            color = '#cf6678';
          } else if (data.paystatus === 4) {
            //inv text is red
            color = 'green';
          }
          $('td', row).css('color', color);
        },
        "responsive": true, "lengthChange": false, "autoWidth": false,
      });

    }
  },
  mounted() {
    if(this.searchx === 'payments'){
      this.filterForm.paystatus = 1
    } else if(this.searchx === 'pending'){
      this.filterForm.paystatus = 9
    }
    this.$store.dispatch('user/setLocations')
    if (this.acctnum === undefined) {

      let currentDate = new Date();

      // Add 6 months to the current date
      currentDate.setMonth(currentDate.getMonth() - 6);

      // Format the date in YYYY-MM-DD format
      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, '0');
      let day = String(currentDate.getDate()).padStart(2, '0');
      let formattedDate = `${year}-${month}-${day}`;


      let currentDT = new Date().toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
      }).slice(0, 10);
      let currentDTx = currentDT.split('/');
      let currentDTF = currentDTx[2] + '-' + currentDTx[0] + '-' + currentDTx[1];
      this.filterForm.invoiceDateFrom = formattedDate;
      this.filterForm.invoiceDateTo = currentDTF;

      this.loadData()
    } else {
      this.filterForm.Acctnum = this.acctnum
    }

    this.sumBatchModal = new Modal(document.getElementById("sumBatchModal"));

  }
}
</script>

<style scoped>

#orders1 thead th {
  white-space: nowrap;
}

.card:hover {
  overflow: hidden;
  transform: none;
}

</style>